<template>
  <div id="my-support-ticket-list">
    <v-overlay :value="OverlayLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="py-0">
      <v-speed-dial
        v-model="fab"
        :top="top"
        :bottom="bottom"
        :right="right"
        :left="left"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
        :fixed="true"
        v-if="SpeedDialFlag"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-account-circle </v-icon>
          </v-btn>
        </template>
        <!-- <v-btn fab dark small color="green">
            <v-icon>mdi-pencil</v-icon>
          </v-btn> -->
        <v-btn fab dark small color="indigo" @click.prevent="addRecord">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <!-- <v-btn fab dark small color="red">
            <v-icon>mdi-delete</v-icon>
          </v-btn> -->
      </v-speed-dial>
    </v-container>

    <v-dialog v-model="addRecordPrompt" persistent max-width="75%">
      <my-support-ticket-create
        :addRecordPrompt="addRecordPrompt"
        @hideDialog="hideAddDialog"
        v-if="addRecordPrompt"
      ></my-support-ticket-create>
    </v-dialog>

    <v-dialog v-model="previewRecordPrompt" persistent max-width="75%">
      <my-support-ticket-preview
        :pageTitle="previewRecordPageTitle"
        :previewRecordFlag="previewRecordFlag"
        :recordData="selectedData"
        @hidePreviewRecordPrompt="hidePreviewRecordPrompt"
        v-if="previewRecordPrompt"
      ></my-support-ticket-preview>
    </v-dialog>

    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4></v-card-subtitle
      >

      <v-card-text>
        <v-row wrap>
          <v-col align="start" cols="12" md="12">
            <div class="notes-content">
              <!-- <p><span class="text-danger">*</span> indicates required field</p> -->
            </div>
          </v-col>
        </v-row>
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-btn
              @click.prevent="addRecordPrompt = true"
              color="#8950FC"
              elevation="30"
              shaped
              large
              class="font-size-h6 mr-3 my-3 white--text"
            >
              &nbsp; Create &nbsp;
            </v-btn>
            <v-btn
              large
              @click.prevent="refreshPageData"
              color="#a4c639"
              elevation="30"
              shaped
              class="font-size-h6 mr-3 my-3 white--text"
            >
              <v-icon dark> mdi-refresh </v-icon> &nbsp; Refresh
            </v-btn>
            <!-- <router-link to="/lom-events/create">
                <v-btn
                  large
                  color="#00a4ef"
                  elevation="30"
                  shaped
                  class="font-size-h6 mr-3 my-3 white--text"
                >
                  <v-icon dark> mdi-file-document-edit </v-icon> &nbsp; Create
                  event
                </v-btn>
              </router-link> -->
          </v-col>
        </v-row>
        <br /><br />
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container
          class="py-0"
          v-if="tableData1.length == 0 && LoadingFlag == false"
        >
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>No records found.</h3>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>{{ tableData1.length }} records found.</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <!-- <v-btn v-if="DeleteFlag == 1" color="error" class="mr-4" @click="deleteRecords">
                    Delete
                  </v-btn> -->
              <p></p>
              <json-excel
                v-if="
                  tableData1.length > 0 &&
                  tableOptions1.JsonDownloadButtonFlag == true
                "
                :name="tableOptions1.ExcelFileName"
                :data="tableData1"
                :fields="tableOptions1.JsonExcelFields"
              >
                <v-btn color="info" class="mr-4"> Download </v-btn>
              </json-excel>
            </v-col>
            <p></p>
          </v-row>
          <v-container class="py-0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  small
                  elevation="30"
                  shaped
                  tile
                  class="font-size-h6 mr-3 white--text"
                  v-if="ExcelDownloadFlag == true && ExcelFileFlag == false"
                  @click.prevent="generateReportExcel"
                  :loading="ExcelLoadingFlag"
                  target="_blank"
                  color="#ff7f50"
                >
                  Generate Report
                </v-btn>
                <v-btn
                  elevation="30"
                  shaped
                  tile
                  small
                  class="font-size-h6 mr-3 white--text"
                  v-if="ExcelFileName != '' && ExcelFileFlag == true"
                  :href="ExcelFileName"
                  target="_blank"
                  color="#2fb65a"
                >
                  <v-icon dark> mdi-download </v-icon> Download Report
                </v-btn>
              </v-col>
            </v-row>
            <p></p>
          </v-container>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <!-- <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search records here"
                  single-line
                  hide-details
                ></v-text-field> -->
              <br />
              <v-data-table
                class="elevation-1"
                loading-text="Loading... Please wait"
                v-model="selected"
                :search="search"
                :loading="TableLoadingFlag"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
                :hide-default-footer="tableOptions1.HideDefaultFooter"
              >
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                    >{{ item.ActiveStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:item.ProfilePic="{ item }">
                  <img width="100" height="100" :src="item.ProfilePic" />
                </template>
                <template v-slot:item.MonthlyEventsFlag="{ item }">
                  <v-tooltip
                    bottom
                    v-if="item.MonthlyEventsFlag && item.Total > 0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="item.MonthlyEventsFlag && item.Total > 0"
                        @click="editData(item, e)"
                        target="_blank"
                        color="primary"
                        small
                        class="text-white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-file-find </v-icon> Events
                      </v-btn>
                    </template>
                    <span> {{ item.MonthName }} Events </span>
                  </v-tooltip>
                </template>
                <template v-slot:item.FileGenerateFlag="{ index, item }">
                  <v-tooltip
                    bottom
                    v-if="item.FileGenerateFlag && item.Total > 0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="item.MonthlyEventsFlag && item.Total > 0"
                        @click="generateReport(index, item)"
                        target="_blank"
                        color="red"
                        small
                        class="text-white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-file-pdf </v-icon> Generate
                      </v-btn>
                    </template>
                    <span> Generate {{ item.MonthName }} MRF </span>
                  </v-tooltip>
                </template>
                <template v-slot:item.FileDownloadFlag="{ item }">
                  <v-tooltip
                    bottom
                    v-if="item.FileDownloadFlag && item.FilePath != ''"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="item.FilePath != '' && item.FileDownloadFlag == 1"
                        :href="item.FilePath"
                        target="_blank"
                        color="#2fb65a"
                        small
                        class="text-white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-download </v-icon> Download
                      </v-btn>
                    </template>
                    <span> {{ item.MonthName }} MRF Download </span>
                  </v-tooltip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom v-if="item.PreviewFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="blue"
                        @click="previewData(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-search
                      </v-icon>
                    </template>
                    <span> Preview </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.EditFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="warning"
                        @click="editData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-document-edit
                      </v-icon>
                    </template>
                    <span> Edit </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.DeleteFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="red"
                        @click="deleteConfirmAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span> Delete </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>
  
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import MySupportTicketCreate from "@/view/pages/erp/my-support-ticket/MySupportTicketCreate.vue";
import MySupportTicketPreview from "@/view/pages/erp/my-support-ticket/MySupportTicketPreview.vue";

export default {
  mixins: [common],
  components: {
    MySupportTicketCreate,
    MySupportTicketPreview,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      SpeedDialFlag: false,
      TableLoadingFlag: false,
      OverlayLoadingFlag: false,

      rows: [],

      Modules: {},

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",

      recordToEdit: "",
      record: {},
      rowData: {},
      addRecordPrompt: false,

      previewRecordFlag: false,
      previewRecordPrompt: false,
      previewRecordPageTitle: "",

      selectedData: {},

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
    };
  },
  mounted() {
    /*
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Vuetify", route: "alerts" },
        { title: "Form Inputs & Control", route: "autocompletes" },
        { title: "Fileinptus" },
      ]);
      */
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.JciYearCode = tr.CurrentJciYearId;
        this.YearName = tr.CurrentJciYearName;
        this.searchForm();
      }
    },
  },
  methods: {
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    hideAddDialog() {
      console.log("hideAddDialog called");
      this.addRecordPrompt = false;
      this.refreshPageData();
    },
    resetForm() {
      this.$refs.form2.reset();
      this.tableData1 = [];
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    refreshPageData() {
      console.log("refreshPageData called");

      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "support_tickets",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    searchForm() {
      console.log("searchForm is called");

      var message = "";

      // var validate1 = this.$refs.form2.validate();
      // console.log("validate1=" + validate1);

      this.LoadingFlag = true;
      this.tableData1 = [];
      this.ExcelDownloadFlag = false;
      this.ExcelFileName = "";
      this.ExcelFileFlag = false;
      this.Modules = {};

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/ticket/lists";
      var upload = {
        UserInterface: 2,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = {};
      var table = {};
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.LoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);
          console.log({ records });

          if (flag == 1) {
            var options = records.TableOptions;
            console.log({ options });
            thisIns.tableColumns1 = records.TableHeader;
            thisIns.tableOptions1 = options;
            thisIns.ExcelDownloadFlag = options.ExcelDownloadButtonFlag;
            thisIns.tableData1 = records.TableData;

            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.LoadingFlag = false;
        });
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
      this.searchForm();
      this.refreshPageData();
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.previewRecordPrompt = true;
        this.previewRecordFlag = true;
        this.previewRecordPageTitle = "My Ticket Preview";
        this.selectedData = tr;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  <style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#lom-events-monthly-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody tr:nth-of-type(odd) {
    background-color: #cfdadd;
  }
}
</style>